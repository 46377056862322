@import '../../styles/customMediaQueries.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--colorFail);
}

.aside {
  box-shadow: none;
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
  }
}

.avatar {
  margin: 30px 26px 29px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    margin: 0 96px 48px 0;
  }
}
.avatarPlaceholder {
  composes: avatar;
  width: 96px;
}

.mobileHeading {
  flex-shrink: 0;
  margin: 60px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
    padding: 6px 0 2px 0;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 0 24px 0;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  composes: clearfix from global;
  border-top: 1px solid var(--colorGrey100);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  color: var(--colorGrey700);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 56px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--colorGrey100);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;
  &:first-of-type {
    margin-top: 18px;
  }

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(50% - 12px);
    margin-bottom: 36px;

    /* Horizontal space */
    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    }

    /* Vertical space */
    margin-top: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }
}

.mobileReviewsTitle {
  margin: 24px 0 26px 0;
  font-size: medium;
}

.mobileReviews {
  border-top: 1px solid var(--colorGrey100);
  margin-bottom: 36px;
}

.desktopReviews {
  border-top: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.desktopReviewsWrapper {
  max-width: 600px;
  margin-top: 28px;
}

.desktopReviewsTitle {
  composes: textLarge from global;
  margin-bottom: 0;
  font-size: large;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

/* 
 * Custom field component classes 
 */

.sectionHeading {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionMultiEnum,
.sectionDetails,
.sectionEmbeddedYoutubeVideo {
  padding: 0 24px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.video {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.flexCertification {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 20px 0px;
}

.thumbImage {
  width: 100%;
  height: 200px;
}

.thumbFile {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  /* height: 100%;
  width: 100%; */
  padding: 0 12px;

  /* Style */
  background-color: var(--colorGrey100);
  width: 100%;
  height: 200px;
}

.thumbFileText {
  /* Font */
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  /* Limit text to 2 lines */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.thumb {
  position: relative;

  /* Layout */
  display: inline-flex;
  padding: 4px;
  height: 200px;
  width: 44%;

  /* Style */
  border-radius: 2px;
  border: 1px solid var(--marketplaceColor);
}

.thumbInner {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.actionButtons {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  /* Position */
  position: absolute;
  display: flex;
  top: 10px;
  left: 0;
  right: 0;
  padding: 0 calc(4px + 8px);
  z-index: 1;
}

.reviewBtton {
  padding: 8px;
  border: 1px solid black;
  border-radius: 4px;
  width: max-content;
  margin: 12px 0px;
  cursor: pointer;
}

.reviewBtton:hover {
  background-color: var(--marketplaceColor);
  color: white;
}

.reviewContent {
  composes: textSmall from global;
  font-style: italic;
  margin: 0;
  white-space: pre-line;
}

.publicReviewTitle {
  font-size: large;
  margin-bottom: 12px;
  font-weight: bold;
}

.publicReview {
  padding: 12px 0px;
}

.publicReviewContent {
  padding: 16px 0px;
}

.publicReviewComponent {
  padding: 20px 0px;
}

.publicReviewName {
  display: flex;
  gap: 8px;
  text-align: center;
  color: lightgrey;
  font-size: 12px;
  font-weight: bold;
}
.displayFlex {
  display: flex;
  gap: 4px;
  padding: 4px 0px;
}

.displayFlexDesktop {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    gap: 4px;
  }
}
